// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
export const SEND_UPLOAD_PROGRESS_EVENT = 'sendUploadProgress';
export const SEND_DOWNLOAD_PROGRESS_EVENT = 'sendDownloadProgress';
export const NETWORK_ERROR_MESSAGE = 'Network Error';
export const NETWORK_ERROR_CODE = 'ERR_NETWORK';
export const ABORT_ERROR_MESSAGE = 'Request aborted';
export const ABORT_ERROR_CODE = 'ERR_ABORTED';
export const CANCELED_ERROR_MESSAGE = 'canceled';
export const CANCELED_ERROR_CODE = 'ERR_CANCELED';
export const CONTENT_SHA256_HEADER = 'x-amz-content-sha256';
